import React, { FC } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Theme } from '@material-ui/core'
import SEO from '../components/seo'
import { Typography, Container } from '@material-ui/core'
import img404 from '../images/404.png'
import TextMarkdown from '../components/customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // marginTop: theme.spacing(14),
    marginBottom: theme.spacing(3),
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}))

const indication = `Vous pouvez retourner à [l'accueil](/), ou consulter [les ressources](/ressources)`

const NotFoundPage: FC = () => {
  const classes = useStyles()
  return (
    <Container maxWidth="md" className={classes.root}>
      <SEO title="404: Not found" />
      <Box className={classes.container}>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom={true}>
            Oups, cette page n'existe pas
          </Typography>
          <Box margin="auto" paddingY={2}>
            <img src={img404} alt="404 image" width="100%" />
          </Box>
          <Typography variant="h4" gutterBottom={true}>
            <TextMarkdown text={indication} />
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default NotFoundPage
